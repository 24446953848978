.category {
    width: 100%;
    background-color: #FFFFFF;
}
.category-container {
    padding: 20px;
    /* max-width: 1200px; */
    margin: 0 auto;
  }
  .category-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    /* height: 600px; */
  }
  
  .category-table th, .category-table td {
    border-bottom: 1px solid #E2E8F0;
    padding: 8px;
    text-align: left;
    color: #718096;
    margin-top: 40px;
    height: 30px;
    font-family:'Roboto';
  }
  
  .category-table th {
    background-color: #EBF8FF;
    font-weight: bold;
  }
  
  
  .category-table tr:hover {
    background-color: #ddd;
  }
  
  .error {
    color: red;
  }
  