.login {
    background-color: #FFFFFF;
    display:flex;
    /* align-items: center; */
    justify-content: center;
    height: 400px;
    width: 400px;
    margin-top: 30px;
}
.heading {
    margin-top:90px;
    color: #544BBB;
    font-family: 'Roboto';
}
input {
    height: 40px;
    width: 300px;
    margin-top:15px;
    border:none;
}
input:focus {
    outline: none;
}
.btn {
    height: 40px;
    width: 200px;
    color: #FFFFFF;
    margin-top: 30px;
}