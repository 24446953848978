.add-assignment-container {
    width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-control:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .question-group {
    margin-top: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #eee;
  }
  
  .option-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .remove-button, .add-question-button, .submit-button {
    display: inline-block;
    padding: 10px 15px;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .remove-button {
    background-color: #dc3545;
    margin-top: 0;
  }
  
  .add-question-button {
    background-color: #28a745;
  }
  
  .remove-button:hover, .add-question-button:hover, .submit-button:hover {
    opacity: 0.9;
  }
  
  .success-message {
    color: #28a745;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .error-message {
    color: #dc3545;
    text-align: center;
    margin-bottom: 15px;
  }
  